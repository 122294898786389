<template>
    <el-col :span="24" style="background-color: #ffffff">
        <el-col :span="24">
            <div class="container">
                <div class="handle-box">
                    <!-- <el-select v-model="schoolIndex" placeholder="资产组" style="float: left; margin-right: 10px" @change="changeSchool">
                        <el-option v-for="item in groupSearchList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select> -->
                    <div class="handle-input mr10">
                        <el-cascader v-model="groupSearchIndex" style="width: 100%" :options="groupSearchList" clearable></el-cascader>
                    </div>
                    <el-input v-model="query.keyword" placeholder="模糊检索" class="handle-input mr10"></el-input>
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
                </div>
                <div class="handle-box">
                    <el-button style="margin-right: 10px" type="primary" icon="el-icon-plus" @click="addBut" class="handle-del mr10 ml10"
                        >添加资产</el-button
                    >
                    <el-button style="margin-right: 10px" type="danger" icon="el-icon-delete" @click="batchBut" class="handle-del mr10 ml10"
                        >批量删除</el-button
                    >
                </div>
                <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
                    <el-table-column prop="name" label="资产名" align="center"></el-table-column>
                    <el-table-column prop="numb" label="资产编号" align="center"></el-table-column>
                    <el-table-column prop="remark" label="备注" align="center"></el-table-column>
                    <el-table-column label="操作" align="center" style="display: flex; justify-content: center; align-items: center">
                        <template slot-scope="scope" style="display: flex" @click="handleSelect(scope.row)">
                            <el-button
                                type="text"
                                style="margin-right: 10px"
                                icon="el-icon-edit"
                                @click="reviseBut(scope.$index, scope.row)"
                                >修改</el-button
                            >
                            <el-button type="text" icon="el-icon-delete" class="red" @click="handledelete(scope.$index, scope.row)"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        background
                        layout="total, prev, pager, next,sizes, jumper"
                        :current-page="query.pageIndex"
                        @size-change="handleSizeChange"
                        :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 500, 1000, 2000, 3000, 4000, 5000]"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                    ></el-pagination>
                </div>
            </div>
        </el-col>
        <!-- 添加弹出框 -->
        <el-dialog title="资产添加" :visible.sync="addstate" width="30%">
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="资产组" :required="true">
                    <!-- <el-select v-model="schoolIndex" placeholder="资产组" @change="changeSchool">
                        <el-option v-for="item in groupSearchList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select> -->
                    <el-cascader v-model="groupAddIndex" style="width: 100%" :options="groupSearchList" clearable></el-cascader>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="资产名" :required="true">
                    <el-input v-model="add.groupName" placeholder="请输入资产名"></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="资产编号" :required="true">
                    <el-input v-model="add.ucn" placeholder="请输入资产编号"></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="备注">
                    <el-input v-model="add.remark" placeholder="请输入备注"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addstate = false">取 消</el-button>
                <el-button type="primary" @click="addOk">确定</el-button>
            </span>
        </el-dialog>
        <!-- 修改弹出框 -->
        <el-dialog title="修改" :visible.sync="revisestate" width="30%">
            <el-form ref="form" :model="revise" label-width="150px">
                <el-form-item label="资产组" :required="true">
                    <el-cascader v-model="groupReviseIndex" style="width: 100%" :options="groupSearchList" clearable></el-cascader>
                    <!-- <el-select v-model="schoolIndex" placeholder="资产组" @change="changeSchool">
                        <el-option v-for="item in groupSearchList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select> -->
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="revise" label-width="150px">
                <el-form-item label="资产名" :required="true">
                    <el-input
                        v-model="revise.name"
                        @input="inputChange($event)"
                        placeholder="请输入资产名"
                        @keyup.enter.native="reviseOk"
                    ></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="revise" label-width="150px">
                <el-form-item label="资产编号" :required="true">
                    <el-input v-model="revise.ucn" placeholder="请输入资产编号" @input="inputChange($event)"></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="revise" label-width="150px">
                <el-form-item label="备注">
                    <el-input v-model="revise.remark" placeholder="请输入备注" @input="inputChange($event)"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="revisestate = false">取 消</el-button>
                <el-button type="primary" @click="reviseOk">确定</el-button>
            </span>
        </el-dialog>
    </el-col>
</template>

<script>
import { fetchData } from '../../api/index';
export default {
    data() {
        return {
            query: {
                pageIndex: 1,
                pageSize: 10
            },
            tableData: [],
            pageTotal: 0,
            addstate: false,
            add: {},
            schoolIndex: '',
            groupSearchList: [],
            batchList: [],
            revise: {},
            revisestate: false,
            groupAddIndex: '',
            groupReviseIndex: '',
            groupSearchIndex: ['1']
        };
    },
    activated() {},
    created() {
        var that = this;
        var query = {
            url: '/get_assetsGroupCascader.cls.php',
            data: {
                page: 1,
                limit: 9999
            }
        };
        fetchData(query).then((res) => {
            console.log(res);
            this.groupSearchList = res.data;
            this.groupSearchIndex = [];
            this.groupSearchIndex = this.groupFor(this.groupSearchList);
            this.groupSearchIndex = this.groupSearchIndex.slice(0, this.groupSearchIndex.length - 1);
            this.getgoodList();
        });
    },
    methods: {
        // 添加
        addBut() {
            this.addstate = true;
        },
        //添加确认
        addOk() {
            var that = this;
            console.log(this.groupAddIndex);
            if (that.add.groupName == undefined || that.add.groupName == '') {
                this.$message.error('资产命不能为空');
            } else if (that.add.ucn == undefined || that.add.ucn == '') {
                this.$message.error('资产编号不能为空');
            } else {
                var groupAddIndex = 0;
                if (this.groupAddIndex) {
                    groupAddIndex = this.groupAddIndex[this.groupAddIndex.length - 1];
                }
                var query = {
                    data: {
                        groupId: groupAddIndex,
                        name: that.add.groupName,
                        numb: that.add.ucn,
                        remark: that.add.remark
                    },
                    url: '/create_assets.cls.php'
                };
                fetchData(query).then((res) => {
                    if (res.code == 0) {
                        this.addstate = false;
                        this.$message.success(res.msg);
                        this.getgoodList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        // 修改
        reviseBut(index, row) {
            var that = this;
            this.groupReviseIndex = [];
            this.groupReviseIndex = this.groupForEach(row.group, this.groupSearchList);
            console.log(this.groupReviseIndex);
            this.revise.name = row.name;
            this.revise.ucn = row.numb;
            this.revise.remark = row.remark;
            this.reviseId = row.id;
            this.revisestate = true;
        },
        groupForEach(groupId, groupData) {
            for (var i = 0; i < groupData.length; i++) {
                if (groupData[i].value == groupId) {
                    return [groupData[i].value];
                } else {
                    if (groupData[i].children) {
                        var temp = this.groupForEach(groupId, groupData[i].children);
                        if (temp) {
                            var data = [groupData[i].value];
                            return data.concat(temp);
                        }
                    }
                }
            }
        },
        groupFor(groupData) {
            for (var i = 0; i < groupData.length; i++) {
                if (groupData[i].children) {
                    var temp = this.groupFor(groupData[i].children);
                    if (temp) {
                        var data = [groupData[i].value];
                        return data.concat(temp);
                    }
                } else {
                    var data = [groupData[i].value];
                    return data.concat(temp);
                }
            }
        },
        // 修改上传
        reviseOk() {
            var that = this;
            if (that.revise.name == undefined || that.revise.name == '') {
                this.$message.error('资产命不能为空');
            } else if (that.revise.ucn == undefined || that.revise.ucn == '') {
                this.$message.error('资产编号不能为空');
            } else {
                var groupReviseIndex = 0;
                if (this.groupReviseIndex) {
                    groupReviseIndex = this.groupReviseIndex[this.groupReviseIndex.length - 1];
                }
                var query = {
                    data: {
                        id: that.reviseId,
                        type: 'sole',
                        groupId: groupReviseIndex,
                        name: that.revise.name,
                        numb: that.revise.ucn,
                        remark: that.revise.remark
                    },
                    url: '/update_assets.cls.php'
                };
                fetchData(query).then((res) => {
                    if (res.code == 0) {
                        this.revisestate = false;
                        this.$message.success(res.msg);
                        this.getgoodList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        // 删除操作
        handledelete(index, row) {
            console.log(row);
            var that = this;
            // 二次确认删除
            this.$confirm('确定要删除该资产吗？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    var query = {
                        data: {
                            delete: 1,
                            type: 'sole',
                            id: row.id
                        },
                        url: '/update_assets.cls.php'
                    };
                    fetchData(query).then((res) => {
                        if (res.code == 0) {
                            that.$message.success(res.msg);
                            that.getgoodList();
                        } else {
                            that.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        //批量删除
        batchBut() {
            var that = this;
            if (this.batchList == undefined || that.batchList == '') {
                this.$message.error('请先勾选');
            } else {
                // 二次确认删除
                this.$confirm('确定要删除该资产吗？', '提示', {
                    type: 'warning'
                })
                    .then(() => {
                        var query = {
                            data: {
                                delete: 1,
                                type: 'batch',
                                id: JSON.stringify(this.batchList)
                            },
                            url: '/update_assets.cls.php'
                        };
                        fetchData(query).then((res) => {
                            if (res.code == 0) {
                                that.$message.success(res.msg);
                                that.getgoodList();
                            } else {
                                that.$message.error(res.msg);
                            }
                        });
                    })
                    .catch(() => {});
            }
        },
        // 获取列表数据
        getgoodList() {
            var that = this;
            console.log(this.groupSearchIndex);
            var groupSearchIndex = 0;
            if (this.groupSearchIndex) {
                groupSearchIndex = this.groupSearchIndex[this.groupSearchIndex.length - 1];
            }
            var query = {
                data: {
                    page: that.query.pageIndex,
                    limit: that.query.pageSize,
                    groupId: groupSearchIndex,
                    keyword: that.query.keyword
                },
                url: '/get_assetsList.cls.php'
            };
            fetchData(query).then((res) => {
                if (res.code == 0) {
                    // this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
                that.tableData = res.data;
                that.pageTotal = Number(res.count) || 0;
            });
        },
        // 用户搜索
        handleSearch() {
            (this.query.pageIndex = 1), this.getgoodList();
        },
        // 批量操作
        handleSelectionChange(val) {
            this.batchList = [];
            for (var i = 0; i < val.length; i++) {
                this.batchList.push(val[i].id);
            }
            console.log(this.batchList);
        },
        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val;
            this.$set(this.query, 'pageIndex', val);
            this.getgoodList();
        },
        handleSizeChange(val) {
            this.query.pageSize = val;
            this.getgoodList();
        },
        inputChange() {
            this.$forceUpdate();
        }
    }
};
</script>

<style scoped>
.el-cascader {
    width: 90%;
    padding-right: 10px;
}
.demonstration {
    color: #948c8c;
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 200px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.block2 {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
</style>
