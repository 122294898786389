<template>
    <div class="login-wrap">
        <div class="ms-login">
            <div class="ms-title">华立园-宿舍管理</div>
            <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                <el-image style="width: 100%; height: 100%" :src="refreshCodeUrl"></el-image>
                <!-- <image src="http://admin.hlpaper.hlsywy.com/class/get_loginQrCode.cls.php"></image> -->
                <div class="login-btn">
                    <el-button type="primary" @click="refreshCode">刷新二维码</el-button>
                </div>
                <!-- <div class="login-btn">
                    <el-button type="primary" @click="submitForm()">登录</el-button>
                </div> -->
                <!-- <p class="login-tips">Tips : sh。</p> -->
            </el-form>
        </div>
    </div>
</template>

<script>
import { fetchData } from '../../api/index';
import request from '../../utils/request';
import axios from 'axios';
export default {
    data: function () {
        return {
            param: {
                username: '1123',
                password: '1'
            },
            rules: {
                username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
            },
            refreshCodeUrl: '',
            index: 10,
            token: ''
        };
    },
    created() {
        window.sessionStorage.setItem('Token', '');
        var that = this;
        var query = {
            url: '/get_loginInfo.cls.php'
        };
        fetchData(query).then((res) => {
            window.sessionStorage.setItem('Token', res.Token);
            that.refreshCode();
            that.scan();
        });
    },
    methods: {
        // 监听扫码
        scan() {
            var that = this;
            var query = {
                url: '/get_loginInfo.cls.php'
            };
            fetchData(query).then((res) => {
                window.sessionStorage.setItem('Token', res.Token);
                if (res.code == 0) {
                    this.$message.success('登录成功');
                    localStorage.setItem('ms_username', res.name);
                    // window.sessionStorage.setItem('powerType', JSON.stringify(res.power));
                    this.$router.push('/');
                } else {
                    setTimeout(function () {
                        that.refreshCode();
                        that.scan();
                    }, 5000);
                }
            });
        },
        submitForm() {
            localStorage.setItem('ms_username', this.param.username);
            this.$router.push('/');
        },
        refreshCode() {
            // this.refreshCodeUrl =
            //     'http://admin.dormi.hlsywy.com/class/get_loginQrCode.cls.php?t=' +
            //     Date.parse(new Date()) / 1000 +
            //     '&Token=' +
            //     window.sessionStorage.getItem('Token');
            this.refreshCodeUrl =
                '/class/get_loginQrCode.cls.php?t=' + Date.parse(new Date()) / 1000 + '&Token=' + window.sessionStorage.getItem('Token');
        }
    }
};
</script>

<style scoped>
.login-wrap {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-image: url(../../assets/img/download.jpg);
    background-size: 100%;
}
.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    /* border-bottom: 1px solid #ddd; */
}
.ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    margin: -190px 0 0 -175px;
    border-radius: 5px;
    /* background: rgba(255, 255, 255, 0.3); */
    overflow: hidden;
}
.ms-content {
    padding: 30px 30px;
    text-align: center;
}
.login-btn {
    text-align: center;
}
.login-btn button {
    width: 200px;
    height: 36px;
    margin-bottom: 10px;
    margin-top: 30px;
}
.login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
}
</style>