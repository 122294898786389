import Vue from 'vue';
import Router from 'vue-router';
import Print from 'vue-print-nb'
Vue.use(Print)
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/',
            component: () => import(/* webpackChunkName: "home" */ '../components/common/Home.vue'),
            meta: { title: '自述文件' },
            children: [
                {
                    path: '/home',
                    component: () => import(/* webpackChunkName: "home" */ '../components/page/home.vue'),
                    meta: { title: '华立园-宿舍管理' }
                },
                {
                    path: '/controlManage',
                    component: () => import(/* webpackChunkName: "controlManage" */ '../components/page/controlManage.vue'),
                    meta: { title: '宿舍管理' }
                },
                {
                    path: '/groupManage.',
                    component: () => import(/* webpackChunkName: "groupManage" */ '../components/page/groupManage.vue'),
                    meta: { title: '分组管理' }
                },
                {
                    path: '/labelGroupManage',
                    component: () => import(/* webpackChunkName: "labelGroupManage" */ '../components/page/labelGroupManage.vue'),
                    meta: { title: '标签组管理' }
                },
                {
                    path: '/labelManage.',
                    component: () => import(/* webpackChunkName: "labelManage" */ '../components/page/labelManage.vue'),
                    meta: { title: '标签管理' }
                },
                {
                    path: '/dormLabel.',
                    component: () => import(/* webpackChunkName: "dormLabel" */ '../components/page/dormLabel.vue'),
                    meta: { title: '宿舍标签绑定' }
                },
                {
                    path: '/assetsManage.',
                    component: () => import(/* webpackChunkName: "assetsManage" */ '../components/page/assetsManage.vue'),
                    meta: { title: '资产管理' }
                },
                {
                    path: '/assetsBatch.',
                    component: () => import(/* webpackChunkName: "assetsBatch" */ '../components/page/assetsBatch.vue'),
                    meta: { title: '资产批量导入' }
                },
                {
                    path: '/dormManage.',
                    component: () => import(/* webpackChunkName: "dormManage" */ '../components/page/dormManage.vue'),
                    meta: { title: '宿舍管理' }
                },
                {
                    path: '/dormBatch.',
                    component: () => import(/* webpackChunkName: "dormBatch" */ '../components/page/dormBatch.vue'),
                    meta: { title: '宿舍批量添加' }
                },
                {
                    path: '/assetsGroupManage.',
                    component: () => import(/* webpackChunkName: "assetsGroupManage" */ '../components/page/assetsGroupManage.vue'),
                    meta: { title: '资产组' }
                },
                {
                    path: '/dormAssets.',
                    component: () => import(/* webpackChunkName: "dormAssets" */ '../components/page/dormAssets.vue'),
                    meta: { title: '宿舍资产绑定' }
                },
                {
                    path: '/updateCascader.',
                    component: () => import(/* webpackChunkName: "updateCascader" */ '../components/page/updateCascader.vue'),
                    meta: { title: '更新缓存' }
                },

                // {
                //     // vue-schart组件
                //     path: '/charts',
                //     component: () => import(/* webpackChunkName: "chart" */ '../components/page/BaseCharts.vue'),
                //     meta: { title: 'schart图表' }
                // },
                // {
                //     path: '/404',
                //     component: () => import(/* webpackChunkName: "404" */ '../components/page/404.vue'),
                //     meta: { title: '404' }
                // },
                // {
                //     path: '/403',
                //     component: () => import(/* webpackChunkName: "403" */ '../components/page/403.vue'),
                //     meta: { title: '403' }
                // },
            ]
        },
        {
            path: '/login',
            component: () => import(/* webpackChunkName: "login" */ '../components/page/Login.vue'),
            meta: { title: '登录' }
        },
        {
            path: '*',
            redirect: '/404'
        }
    ]
});
