<template>
    <div class="sidebar">
        <el-menu
            class="sidebar-el-menu"
            :default-active="onRoutes"
            :collapse="collapse"
            background-color="rgb(50, 65, 87)"
            text-color="#fff"
            active-text-color="rgb(32, 160, 255)"
            unique-opened
            router
        >
            <template v-for="item in items">
                <template v-if="item.subs">
                    <el-submenu :index="item.index" :key="item.index">
                        <template slot="title">
                            <i :class="item.icon"></i>
                            <span slot="title">{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                                <template slot="title">{{ subItem.title }}</template>
                                <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">{{
                                    threeItem.title
                                }}</el-menu-item>
                            </el-submenu>
                            <el-menu-item v-else :index="subItem.index" :key="subItem.index">{{ subItem.title }}</el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <i :class="item.icon"></i>
                        <span slot="title">{{ item.title }}</span>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
import bus from '../common/bus';
import { fetchData } from '../../api/index';
export default {
    data() {
        return {
            collapse: false,
            items: [
                // {
                //     icon: 'el-icon-help',
                //     index: 'controlManage',
                //     title: '宿舍管理'
                // },

                {
                    icon: 'el-icon-school',
                    index: 'dormManage.',
                    title: '宿舍管理'
                },
                {
                    icon: 'el-icon-folder-add',
                    index: 'dormBatch.',
                    title: '宿舍批量添加'
                },
                {
                    icon: 'el-icon-money',
                    index: 'groupManage.',
                    title: '分组管理'
                },
                {
                    icon: 'el-icon-document-copy',
                    index: 'labelGroupManage',
                    title: '标签组管理'
                },
                {
                    icon: 'el-icon-tickets',
                    index: 'labelManage.',
                    title: '标签管理'
                },
                {
                    icon: 'el-icon-collection-tag',
                    index: 'dormLabel.',
                    title: '宿舍标签绑定'
                },
                {
                    icon: 'el-icon-coin',
                    index: 'assetsGroupManage.',
                    title: '资产组'
                },
                {
                    icon: 'el-icon-connection',
                    index: 'assetsManage.',
                    title: '资产管理'
                },
                {
                    icon: 'el-icon-sort',
                    index: 'assetsBatch.',
                    title: '资产批量导入'
                },
                {
                    icon: 'el-icon-collection',
                    index: 'dormAssets.',
                    title: '宿舍资产绑定'
                },
                {
                    icon: 'el-icon-refresh',
                    index: 'updateCascader.',
                    title: '更新缓存'
                }
            ]
        };
    },
    computed: {
        onRoutes() {
            return this.$route.path.replace('/', '');
        }
    },
    created() {
        // 通过 Event Bus 进行组件间通信，来折叠侧边栏
        bus.$on('collapse', (msg) => {
            this.collapse = msg;
            bus.$emit('collapse-content', msg);
        });
    },
    methods: {
        powerChange() {
            if (window.sessionStorage.getItem('power') == '2') {
                this.items = [];
            } else if (window.sessionStorage.getItem('power') == '1') {
                this.items = [];
            } else if (window.sessionStorage.getItem('power') == '3') {
                this.items = [];
            }
        }
    }
};
</script>

<style scoped>
.sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 250px;
}
.sidebar > ul {
    height: 100%;
}
</style>
