<template>
    <el-col :span="24" style="background-color: #ffffff">
        <el-col :span="24">
            <div class="container">
                <div class="handle-box">
                    <el-select v-model="schoolIndex" placeholder="学校" style="float: left; margin-right: 10px" @change="getRoomGroupList">
                        <el-option v-for="item in schoolIndexList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                    <div class="handle-input mr10">
                        <el-cascader
                            v-model="groupSearchIndex"
                            style="width: 100%"
                            :options="groupSearchList"
                            :props="{ checkStrictly: true }"
                            clearable
                        ></el-cascader>
                    </div>
                    <div class="handle-input mr10">
                        <el-cascader
                            v-model="labelSearchIndex"
                            style="width: 100%"
                            :options="labelList"
                            collapse-tags
                            clearable
                        ></el-cascader>
                        <!-- <el-cascader v-model="groupSelectIndex" style="width: 100%" :options="groupSearchList" clearable></el-cascader> -->
                    </div>
                    <div class="handle-input mr10">
                        <el-select v-model="bedNumSearch" placeholder="床位数" style="width: 100%">
                            <el-option v-for="item in roomSearchList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                        </el-select>
                    </div>
                    <el-input v-model="query.keyword" placeholder="模糊检索(房间名/编号)" class="handle-input mr10"></el-input>
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
                </div>
                <div class="handle-box">
                    <el-button style="margin-right: 10px" type="primary" icon="el-icon-plus" @click="addBut" class="handle-del mr10 ml10"
                        >添加房间</el-button
                    >
                    <el-button style="margin-right: 10px" type="danger" icon="el-icon-delete" @click="batchBut" class="handle-del mr10 ml10"
                        >批量删除</el-button
                    >
                </div>
                <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
                    <el-table-column prop="group" label="所属分组" align="center"></el-table-column>
                    <el-table-column prop="name" label="房间名" align="center"></el-table-column>
                    <el-table-column prop="numb" label="房间编号" align="center"></el-table-column>
                    <el-table-column label="房间标签" align="center">
                        <template slot-scope="scope">
                            <div style="display: flex; flex-flow: wrap">
                                <div v-for="(tipItem, index) in scope.row.icon" :key="index" style="margin: 5px 10px">
                                    <el-tooltip class="item" effect="dark" :content="tipItem.name" placement="left-end">
                                        <el-tag :type="tipItem.color">{{ tipItem.icon }}</el-tag>
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="state" label="房间状态" align="center"></el-table-column>
                    <el-table-column prop="bedNum" label="房间床位数" align="center"></el-table-column>
                    <el-table-column prop="remark" label="备注" align="center"></el-table-column>
                    <el-table-column
                        label="操作"
                        align="center"
                        style="display: flex; justify-content: center; align-items: center"
                        width="220px"
                    >
                        <template slot-scope="scope" style="display: flex">
                            <el-button
                                type="text"
                                style="margin-right: 10px"
                                icon="el-icon-edit"
                                @click="reviseBut(scope.$index, scope.row)"
                                >修改</el-button
                            >
                            <el-button type="text" icon="el-icon-zoom-in" @click="handlecheck(scope.$index, scope.row)">详情</el-button>
                            <el-button type="text" icon="el-icon-delete" class="red" @click="handledelete(scope.$index, scope.row)"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        background
                        layout="total, prev, pager, next,sizes, jumper"
                        :current-page="query.pageIndex"
                        @size-change="handleSizeChange"
                        :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 500, 1000, 2000, 3000, 4000, 5000]"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                    ></el-pagination>
                </div>
            </div>
        </el-col>
        <!-- 添加弹出框 -->
        <el-dialog title="添加" :visible.sync="addstate" width="30%">
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="校区" :required="true">
                    <el-select v-model="schoolIndex" placeholder="校区" @change="getRoomGroupList" style="width: 100%">
                        <el-option v-for="item in schoolIndexList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="宿舍分组" :required="true">
                    <div class="block">
                        <el-cascader v-model="groupSelectIndex" style="width: 100%" :options="groupSearchList" clearable></el-cascader>
                    </div>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="房间标签" :required="true">
                    <div class="block">
                        <el-cascader
                            v-model="labelSelectIndex"
                            style="width: 100%"
                            :options="labelList"
                            :props="{ multiple: true }"
                            collapse-tags
                            clearable
                        ></el-cascader>
                    </div>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="房间名" :required="true">
                    <el-input v-model="add.name" placeholder="请输入房间名"></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="统一编号" :required="true">
                    <el-input v-model="add.ucn" placeholder="请输入统一编号"></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="床位数" :required="true">
                    <el-select v-model="roomAdd" placeholder="床位数" style="width: 100%">
                        <el-option v-for="item in roomList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="房间备注" :required="true">
                    <el-input v-model="add.remark" placeholder="房间备注"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addstate = false">取 消</el-button>
                <el-button type="primary" @click="addOk">确定</el-button>
            </span>
        </el-dialog>
        <!-- 修改弹出框 -->
        <el-dialog title="修改" :visible.sync="revisestate" width="30%">
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="校区" :required="true">
                    <el-select v-model="schoolIndex" placeholder="校区" @change="getRoomGroupList">
                        <el-option v-for="item in schoolIndexList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="revise" label-width="150px">
                <el-form-item label="宿舍分组" :required="true">
                    <div class="block">
                        <el-cascader
                            v-model="groupReviseIndex"
                            style="width: 100%"
                            :options="groupSearchList"
                            :props="{ checkStrictly: true }"
                            clearable
                        ></el-cascader>
                    </div>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="revise" label-width="150px">
                <el-form-item label="房间标签" :required="true">
                    <div class="block">
                        <el-cascader
                            v-model="labelReviseIndex"
                            style="width: 100%"
                            :options="labelList"
                            :props="{ multiple: true }"
                            collapse-tags
                            clearable
                        ></el-cascader>
                    </div>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="revise" label-width="150px">
                <el-form-item label="房间名" :required="true">
                    <el-input
                        v-model="revise.name"
                        @input="inputChange($event)"
                        placeholder="请输入房间名"
                        @keyup.enter.native="reviseOk"
                    ></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="revise" label-width="150px">
                <el-form-item label="统一编号" :required="true">
                    <el-input v-model="revise.ucn" placeholder="请输入统一编号" @input="inputChange($event)"></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="revise" label-width="150px">
                <el-form-item label="床位数" :required="true">
                    <el-select v-model="roomRevise" placeholder="床位数" @change="changeSchool" style="width: 100%">
                        <el-option v-for="item in roomList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="revise" label-width="150px">
                <el-form-item label="房间备注" :required="true">
                    <el-input v-model="revise.remark" placeholder="房间备注" @input="inputChange($event)"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="revisestate = false">取 消</el-button>
                <el-button type="primary" @click="reviseOk">确定</el-button>
            </span>
        </el-dialog>
        <!-- 详情弹出框 -->
        <el-dialog title="宿舍详情" :visible.sync="detailsState" width="70%">
            <el-table :data="detailsList" border style="width: 100%">
                <el-table-column prop="id" label="ID" align="center"></el-table-column>
                <el-table-column prop="region" header-align="center" align="center" label="所在校区"> </el-table-column>
                <el-table-column prop="group" header-align="center" align="center" label="所在分组"> </el-table-column>
                <el-table-column prop="name" header-align="center" align="center" label="宿舍号"> </el-table-column>
                <el-table-column prop="bedNum" header-align="center" align="center" label="床位数"> </el-table-column>
                <el-table-column prop="bedNum" header-align="center" align="center" label="宿舍统一编号"> </el-table-column>
                <el-table-column label="房间标签" align="center" width="200px">
                    <template slot-scope="scope">
                        <div style="display: flex; flex-flow: wrap">
                            <!-- <div v-for="(tipItem, index) in scope.row.label" :key="index" style="margin: 5px 10px"> -->
                            <!-- <el-tooltip class="item" effect="dark" :content="tipItem.name" placement="left-end"> -->
                            <el-tag
                                :type="tag.color"
                                closable
                                @close="handleClose(tag)"
                                v-for="(tag, index) in scope.row.label"
                                :key="index"
                                style="margin: 5px 5px"
                                >{{ tag.icon }}</el-tag
                            >
                            <!-- </el-tooltip> -->
                            <!-- </div> -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" header-align="center" align="center" label="备注"> </el-table-column>
            </el-table>
            <div style="margin: 10px 5px; color: #409eff">床位详情</div>
            <el-table :data="detailsBedList" border style="width: 100%">
                <el-table-column prop="id" label="ID" align="center"></el-table-column>
                <el-table-column prop="name" label="床位名" align="center"></el-table-column>
                <el-table-column prop="numb" label="床位编号" align="center"></el-table-column>
                <el-table-column prop="remark" header-align="center" align="center" label="备注"> </el-table-column>
            </el-table>
            <div style="margin: 10px 5px; color: #409eff">资产详情</div>
            <el-table :data="assetsBedList" border style="width: 100%">
                <el-table-column prop="bindId" label="ID" align="center"></el-table-column>
                <el-table-column prop="group" label="所属资产分组" align="center"></el-table-column>
                <el-table-column prop="name" label="资产名" align="center"></el-table-column>
                <el-table-column prop="numb" label="资产类型编号" align="center"></el-table-column>
                <el-table-column prop="bindNumb" label="资产编号" align="center"></el-table-column>
                <el-table-column prop="remark" label="备注" align="center"></el-table-column>
                <el-table-column
                    label="操作"
                    align="center"
                    style="display: flex; justify-content: center; align-items: center"
                    width="220px"
                >
                    <template slot-scope="scope" style="display: flex">
                        <el-button type="text" icon="el-icon-delete" class="red" @click="assetsdelete(scope.$index, scope.row)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="detailsState = false">取 消</el-button>
                <el-button type="primary" @click="detailsState = false">确定</el-button>
            </span>
        </el-dialog>
    </el-col>
</template>

<script>
import { fetchData } from '../../api/index';
export default {
    data() {
        return {
            query: {
                pageIndex: 1,
                pageSize: 10
            },
            tableData: [],
            pageTotal: 0,
            addstate: false,
            add: {},
            schoolIndex: '',
            schoolIndexList: [],
            groupSearchList: [],
            groupSearchIndex: '',
            groupSelectIndex: '',
            groupReviseIndex: [],
            batchList: [],
            revise: {},
            revisestate: false,
            labelList: [],
            labelSelectIndex: '',
            roomList: [
                {
                    name: '0',
                    id: '0'
                },
                {
                    name: '1',
                    id: '1'
                },
                {
                    name: '2',
                    id: '2'
                },
                {
                    name: '3',
                    id: '3'
                },
                {
                    name: '4',
                    id: '4'
                },
                {
                    name: '5',
                    id: '5'
                },
                {
                    name: '6',
                    id: '6'
                },
                {
                    name: '7',
                    id: '7'
                },
                {
                    name: '8',
                    id: '8'
                },
                {
                    name: '9',
                    id: '9'
                }
            ],
            roomSearchList: [
                {
                    name: '全部',
                    id: 'all'
                },
                {
                    name: '0',
                    id: '0'
                },
                {
                    name: '1',
                    id: '1'
                },
                {
                    name: '2',
                    id: '2'
                },
                {
                    name: '3',
                    id: '3'
                },
                {
                    name: '4',
                    id: '4'
                },
                {
                    name: '5',
                    id: '5'
                },
                {
                    name: '6',
                    id: '6'
                },
                {
                    name: '7',
                    id: '7'
                },
                {
                    name: '8',
                    id: '8'
                },
                {
                    name: '9',
                    id: '9'
                }
            ],
            roomRevise: '',
            roomAdd: '',
            labelSearchIndex: '',
            bedNumSearch: '',
            labelReviseIndex: '',
            detailsState: false,
            detailsList: [],
            detailsBedList: [],
            assetsBedList: []
        };
    },
    activated() {},
    created() {
        var that = this;
        var query = {
            url: '/get_regionList.cls.php'
        };
        fetchData(query).then((res) => {
            this.schoolIndexList = res.data;
            this.schoolIndex = res.data[0].id;
            that.getRoomGroupList();
        });

        var query = {
            url: '/get_labelCascader.cls.php'
        };
        fetchData(query).then((res) => {
            that.labelList = res.data;
        });
    },
    methods: {
        getRoomGroupList() {
            var that = this;
            var query = {
                url: '/get_roomGroupCascader.cls.php',
                data: {
                    region: that.schoolIndex
                }
            };
            fetchData(query).then((res) => {
                that.groupSearchList = res.data;
                this.getgoodList();
            });
        },
        // 资产删除
        assetsdelete(index, row) {
            var that = this;
            // 二次确认删除
            this.$confirm('确定要删除该标签吗？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    var query = {
                        data: {
                            bindId: row.bindId
                        },
                        url: '/delete_bindRoomAssets.cls.php'
                    };
                    fetchData(query).then((res) => {
                        if (res.code == 0) {
                            that.$message.success(res.msg);
                            var changeindex = '';
                            for (var i = 0; i < this.detailsList[0].assets.length; i++) {
                                if (this.detailsList[0].assets[i].bindId == row.bindId) {
                                    changeindex = i;
                                }
                            }
                            this.detailsList[0].assets.splice(changeindex, 1);
                        } else {
                            that.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        // 详情
        handlecheck(index, row) {
            this.changeindex = index;
            var that = this;
            var query = {
                url: '/get_roomInfo.cls.php',
                data: {
                    id: row.id
                }
            };
            fetchData(query).then((res) => {
                that.detailsList = [];
                that.detailsLabelList = [];
                that.detailsList.push(res.data);
                if (res.data.bed) {
                    that.detailsBedList = res.data.bed;
                }
                if (res.data.assets) {
                    that.assetsBedList = res.data.assets;
                }
                that.detailsState = true;
            });
        },
        //移除标签
        handleClose(row, index) {
            var that = this;
            // 二次确认删除
            this.$confirm('确定要删除该标签吗？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    var query = {
                        data: {
                            bindId: row.bindId
                        },
                        url: '/delete_bindRoomLabel.cls.php'
                    };
                    fetchData(query).then((res) => {
                        if (res.code == 0) {
                            that.$message.success(res.msg);
                            var changeindex = '';
                            for (var i = 0; i < this.detailsList[0].label.length; i++) {
                                if (this.detailsList[0].label[i].bindId == row.bindId) {
                                    changeindex = i;
                                }
                            }
                            console.log(changeindex);
                            this.detailsList[0].label.splice(changeindex, 1);
                        } else {
                            that.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        // 添加
        addBut() {
            this.addstate = true;
        },
        //添加确认
        addOk() {
            var that = this;
            console.log(this.groupSelectIndex);
            if (that.add.name == undefined || that.add.name == '') {
                this.$message.error('房间名不能为空');
            } else if (that.groupSelectIndex == undefined || that.groupSelectIndex == '') {
                this.$message.error('请选择宿舍分组');
            } else if (that.labelSelectIndex == undefined || that.labelSelectIndex == '') {
                this.$message.error('请选择房间标签');
            } else {
                var groupSelectIndex = '';
                if (this.groupSelectIndex) {
                    groupSelectIndex = this.groupSelectIndex[this.groupSelectIndex.length - 1];
                }
                var label = [];
                for (var x = 0; x < that.labelSelectIndex.length; x++) {
                    label.push(this.labelSelectIndex[x][this.labelSelectIndex[x].length - 1]);
                }
                var query = {
                    data: {
                        region: that.schoolIndex,
                        name: that.add.name,
                        groupId: groupSelectIndex,
                        label: JSON.stringify(label),
                        numb: that.add.ucn,
                        bedNum: that.roomAdd,
                        remark: that.add.remark
                    },
                    url: '/create_room.cls.php'
                };
                fetchData(query).then((res) => {
                    if (res.code == 0) {
                        this.addstate = false;
                        this.$message.success(res.msg);
                        this.getgoodList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        // 修改
        reviseBut(index, row) {
            var that = this;
            console.log(row);
            this.groupReviseIndex = [];
            this.groupReviseIndex = this.groupForEach(row.groupId, this.groupSearchList);
            console.log(this.groupReviseIndex);
            this.labelReviseIndex = [];
            for (var x = 0; x < this.labelList.length; x++) {
                if (this.labelList[x].children) {
                    for (var y = 0; y < this.labelList[x].children.length; y++) {
                        for (var i = 0; i < row.icon.length; i++) {
                            var labelArr = [];
                            if (this.labelList[x].children[y].value == row.icon[i].id) {
                                labelArr.push(this.labelList[x].value);
                                labelArr.push(row.icon[i].id);
                                this.labelReviseIndex.push(labelArr);
                            }
                        }
                    }
                }
            }
            this.revise.name = row.name;
            this.reviseId = row.id;
            this.revise.ucn = row.numb;
            this.roomRevise = row.bedNum;
            this.revise.remark = row.remark;
            this.revisestate = true;
        },
        groupForEach(groupId, groupData) {
            for (var i = 0; i < groupData.length; i++) {
                if (groupData[i].value == groupId) {
                    return [groupData[i].value];
                } else {
                    if (groupData[i].children) {
                        var temp = this.groupForEach(groupId, groupData[i].children);
                        if (temp) {
                            var data = [groupData[i].value];
                            return data.concat(temp);
                        }
                    }
                }
            }
        },
        // 修改上传
        reviseOk() {
            var that = this;
            console.log(that.labelReviseIndex);
            if (that.revise.name == undefined || that.revise.name == '') {
                this.$message.error('分组命不能为空');
            } else {
                var groupReviseIndex = '';
                if (this.groupReviseIndex) {
                    groupReviseIndex = this.groupReviseIndex[this.groupReviseIndex.length - 1];
                }
                var label = [];
                for (var x = 0; x < that.labelReviseIndex.length; x++) {
                    label.push(this.labelReviseIndex[x][this.labelReviseIndex[x].length - 1]);
                }
                var query = {
                    data: {
                        groupId: groupReviseIndex,
                        id: that.reviseId,
                        region: that.schoolIndex,
                        type: 'sole',
                        name: that.revise.name,
                        label: JSON.stringify(label),
                        numb: that.revise.ucn,
                        bedNum: that.roomRevise,
                        remark: that.revise.remark
                    },
                    url: '/update_room.cls.php'
                };
                fetchData(query).then((res) => {
                    if (res.code == 0) {
                        this.revisestate = false;
                        this.$message.success(res.msg);
                        this.getgoodList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        // 监听学校选择器
        changeSchool() {
            this.getgoodList();
        },
        // 删除操作
        handledelete(index, row) {
            console.log(row);
            var that = this;
            // 二次确认删除
            this.$confirm('确定要删除该分组吗？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    var query = {
                        data: {
                            delete: 1,
                            type: 'sole',
                            id: row.id,
                            region: that.schoolIndex
                        },
                        url: '/update_room.cls.php'
                    };
                    fetchData(query).then((res) => {
                        if (res.code == 0) {
                            that.$message.success(res.msg);
                            that.getgoodList();
                        } else {
                            that.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        //批量删除
        batchBut() {
            var that = this;
            if (this.batchList == undefined || that.batchList == '') {
                this.$message.error('请先勾选');
            } else {
                // 二次确认删除
                this.$confirm('确定要删除该分组吗？', '提示', {
                    type: 'warning'
                })
                    .then(() => {
                        var query = {
                            data: {
                                delete: 1,
                                type: 'batch',
                                region: that.schoolIndex,
                                id: JSON.stringify(this.batchList)
                            },
                            url: '/update_room.cls.php'
                        };
                        fetchData(query).then((res) => {
                            if (res.code == 0) {
                                that.$message.success(res.msg);
                                that.getgoodList();
                            } else {
                                that.$message.error(res.msg);
                            }
                        });
                    })
                    .catch(() => {});
            }
        },
        // 获取列表数据
        getgoodList() {
            var that = this;

            var groupSearchIndex = 0;
            if (this.groupSearchIndex) {
                groupSearchIndex = this.groupSearchIndex[this.groupSearchIndex.length - 1];
            }
            var label = this.labelSearchIndex[this.labelSearchIndex.length - 1];

            var query = {
                data: {
                    page: that.query.pageIndex,
                    limit: that.query.pageSize,
                    region: that.schoolIndex,
                    keyword: that.query.keyword,
                    groupId: groupSearchIndex,
                    label: label,
                    bedNum: this.bedNumSearch
                },
                url: '/get_roomList.cls.php'
            };
            fetchData(query).then((res) => {
                if (res.code == 0) {
                    // this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
                that.tableData = res.data;
                that.pageTotal = Number(res.count) || 0;
            });
        },
        // 用户搜索
        handleSearch() {
            (this.query.pageIndex = 1), this.getgoodList();
        },
        // 批量操作
        handleSelectionChange(val) {
            this.batchList = [];
            for (var i = 0; i < val.length; i++) {
                this.batchList.push(val[i].id);
            }
            console.log(this.batchList);
        },
        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val;
            this.$set(this.query, 'pageIndex', val);
            this.getgoodList();
        },
        handleSizeChange(val) {
            this.query.pageSize = val;
            this.getgoodList();
        },
        inputChange() {
            this.$forceUpdate();
        }
    }
};
</script>

<style scoped>
.el-cascader {
    width: 90%;
    padding-right: 10px;
}
.demonstration {
    color: #948c8c;
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 200px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.block2 {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
</style>
