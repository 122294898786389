<template>
    <el-col :span="24" style="background-color: #ffffff">
        <el-col :span="24">
            <div class="container">
                <div class="handle-box">
                    <el-button type="primary" icon="el-icon-refresh" @click="group">更新宿舍分组缓存</el-button>
                    <el-button type="primary" icon="el-icon-refresh" @click="label">更新标签缓存</el-button>
                    <el-button type="success" icon="el-icon-refresh" @click="assetsGroup">更新资产分组缓存</el-button>
                    <el-button type="success" icon="el-icon-refresh" @click="assets">更新资产缓存</el-button>
                </div>
            </div>
        </el-col>
    </el-col>
</template>

<script>
import { fetchData } from '../../api/index';
export default {
    name: 'basetable',
    data() {
        return {};
    },
    activated() {},
    created() {},
    methods: {
        label() {
            var query = {
                url: '/update_labelCacheCascader.cls.php'
            };
            fetchData(query).then((res) => {
                if (res.code == 0) {
                    this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        group() {
            var query = {
                url: '/update_roomGroupCacheCascader.cls.php'
            };
            fetchData(query).then((res) => {
                if (res.code == 0) {
                    this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        assets() {
            var query = {
                url: '/update_assetsCacheCascader.cls.php'
            };
            fetchData(query).then((res) => {
                if (res.code == 0) {
                    this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        assetsGroup() {
            var query = {
                url: '/update_assetsGroupCacheCascader.cls.php'
            };
            fetchData(query).then((res) => {
                if (res.code == 0) {
                    this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    }
};
</script>

<style scoped>
.el-cascader {
    width: 90%;
    padding-right: 10px;
}
.demonstration {
    color: #948c8c;
}
.handle-box {
    margin-bottom: 20px;
}
.handle-box div {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 200px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.block2 {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
</style>
