<template>
    <div class="login-wrap">
        <div v-for="(item, value) in dormList" :key="value">
            <div class="el-col el-col-6 el-col-xs-12" style="padding-left: 6px; padding-right: 6px">
                <div class="demo-color-box" :class="box(item)">
                    Success
                    <div class="value">#67C23A{{ item }}</div>
                    <div class="bg-color-sub">
                        <div class="bg-success-sub-item" style="background: rgb(225, 243, 216)"></div>
                        <div class="bg-success-sub-item" style="background: rgb(240, 249, 235)"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fetchData } from '../../api/index';
export default {
    data: function () {
        return {
            dormList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        };
    },
    methods: {
        box(res) {
            let colorArr = [];
            console.log(res);
            switch (res) {
                case 1:
                    colorArr.push('box1');
                    break;
                case 2:
                    colorArr.push('box2');
                    break;
                case 3:
                    colorArr.push('box3');
                    break;
                case 4:
                    colorArr.push('box4');
            }
            console.log(colorArr);
            return colorArr;
        }
    }
};
</script>

<style scoped>
.login-wrap {
    background-size: 100%;
}
.demo-color-box {
    position: relative;
    border-radius: 4px;
    padding: 20px;
    margin: 5px 0;
    height: 114px;
    box-sizing: border-box;
    color: #fff;
    font-size: 14px;
}
.bg-color-sub {
    width: 100%;
    height: 40px;
    left: 0;
    bottom: 0;
    position: absolute;
}
.bg-success-sub-item {
    width: 50%;
    height: 100%;
    display: inline-block;
}
.box1 {
    background: rgb(103, 194, 58);
}
.box2 {
    background: rgb(230, 162, 60);
}
.box3 {
    background: rgb(245, 108, 108);
}
.box4 {
    background: rgb(144, 147, 153);
}
</style>