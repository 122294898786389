<template>
    <el-col :span="24" style="background-color: #ffffff">
        <el-col :span="24">
            <div class="container">
                <div class="handle-box">
                    <el-select v-model="schoolIndex" placeholder="学校" style="float: left; margin-right: 10px" @change="getRoomGroupList">
                        <el-option v-for="item in schoolIndexList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                    <div class="handle-input mr10">
                        <el-cascader
                            v-model="groupSearchIndex"
                            style="width: 100%"
                            :options="groupSearchList"
                            :props="{ checkStrictly: true }"
                            clearable
                        ></el-cascader>
                    </div>
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
                </div>

                <el-table
                    ref="multipleTable2"
                    :data="tableData"
                    border
                    class="table"
                    header-cell-class-name="table-header"
                    max-height="300px"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <el-table-column prop="id" label="ID" align="center"></el-table-column>
                    <el-table-column prop="group" label="所属分组" align="center"></el-table-column>
                    <el-table-column prop="name" label="房间名" align="center"></el-table-column>
                    <el-table-column prop="numb" label="房间编号" align="center"></el-table-column>
                    <el-table-column prop="bedNum" label="床位数" align="center"></el-table-column>
                </el-table>
            </div>
            <div class="container">
                <div class="handle-box">
                    <el-select v-model="assetsSearchIndex" placeholder="标签组" style="float: left; margin-right: 10px">
                        <el-option v-for="item in assetsSearchList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                    <el-button type="primary" icon="el-icon-search" @click="handleSearchAssets">搜索</el-button>
                </div>
                <el-table
                    ref="multipleTable1"
                    :data="assetsDate"
                    border
                    class="table"
                    header-cell-class-name="table-header"
                    max-height="300px"
                    @selection-change="assetsSelectionChange"
                >
                    <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
                    <el-table-column prop="name" label="标签名" align="center"></el-table-column>
                    <el-table-column prop="icon" label="图标" align="center"></el-table-column>
                    <el-table-column label="颜色" align="center">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.color == 'success'">绿色</el-tag>
                            <el-tag type="info" v-if="scope.row.color == 'info'">灰色</el-tag>
                            <el-tag type="warning" v-if="scope.row.color == 'warning'">橙色</el-tag>
                            <el-tag type="danger" v-if="scope.row.color == 'danger'">红色</el-tag>
                            <el-tag type="primary" v-if="scope.row.color == ''">蓝色</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-button type="danger" icon="el-icon-delete" @click="handleAssets('delete')" style="float: right; margin: 20px"
                >删除绑定标签</el-button
            >
            <el-button type="warning" icon="el-icon-unlock" @click="handleAssets('replace')" style="float: right; margin: 20px"
                >覆盖绑定标签</el-button
            >
            <el-button type="success" icon="el-icon-unlock" @click="handleAssets('add')" style="float: right; margin: 20px"
                >添加绑定标签</el-button
            >
        </el-col>
    </el-col>
</template>

<script>
import { fetchData } from '../../api/index';
export default {
    name: 'basetable',
    data() {
        return {
            query: {},
            tableData: [],
            assetsDate: [],
            groupSearchList: [],
            groupSearchIndex: '',
            assetsSearchList: [],
            assetsSearchIndex: '',
            schoolIndex: '',
            schoolIndexList: [],
            getState: 1
        };
    },
    created() {
        var that = this;
        var that = this;
        var query = {
            url: '/get_labelGroupList.cls.php',
            data: {
                page: 1,
                limit: 9999
            }
        };
        fetchData(query).then((res) => {
            this.assetsSearchList = res.data;
        });
        var query = {
            url: '/get_regionList.cls.php'
        };
        fetchData(query).then((res) => {
            this.schoolIndexList = res.data;
            this.schoolIndex = res.data[0].id;
            that.getRoomGroupList();
        });
    },
    activated() {},
    methods: {
        getRoomGroupList() {
            var that = this;
            var query = {
                url: '/get_roomGroupCascader.cls.php',
                data: {
                    region: that.schoolIndex
                }
            };
            fetchData(query).then((res) => {
                that.groupSearchList = res.data;
                that.groupSearchIndex = res.data[0].value.toString();
                that.getState = 1;
                this.getgoodList();
            });
        },
        //绑定标签
        handleAssets(row) {
            var that = this;
            if (this.batchList.length == 0) {
                this.$message.error('请勾选房间');
                return;
            }
            if (this.assetsbatchList) {
                if (this.assetsbatchList.length == 0) {
                    this.$message.error('请勾选标签');
                    return;
                }
            } else {
                this.$message.error('请先检索标签');
                return;
            }
            if (row == 'replace') {
                var tit = '确定要覆盖所有标签吗';
            } else if (row == 'delete') {
                var tit = '确定要删除该标签吗';
            } else {
                var tit = '确定要添加该标签吗';
            }
            // 二次确认
            this.$confirm(tit, '提示', {
                type: 'warning'
            })
                .then(() => {
                    var query = {
                        data: {
                            roomList: JSON.stringify(that.batchList),
                            labelList: JSON.stringify(that.assetsbatchList),
                            type: row
                        },
                        url: '/update_bindRoomLabel.cls.php'
                    };
                    fetchData(query).then((res) => {
                        if (res.code == 0) {
                            that.$message.success(res.msg);
                        } else {
                            that.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        // 获取列表数据
        getgoodList() {
            var that = this;
            var groupSearchIndex = this.groupSearchIndex;
            console.log(that.getState);
            if (that.getState == 2) {
                if (this.groupSearchIndex) {
                    groupSearchIndex = this.groupSearchIndex[this.groupSearchIndex.length - 1];
                }
            }
            var query = {
                data: {
                    region: that.schoolIndex,
                    groupId: groupSearchIndex
                },
                url: '/get_bindRoomList.cls.php'
            };
            fetchData(query).then((res) => {
                if (res.code == 0) {
                    // this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
                that.tableData = res.data;
                that.getState = 2;
                // 默认全选
                this.$nextTick(() => {
                    for (let i = 0; i < this.tableData.length; i++) {
                        this.$refs.multipleTable2.toggleRowSelection(this.tableData[i], true);
                    }
                });
            });
        },
        getassetsList() {
            var that = this;
            if (this.assetsSearchIndex == undefined || that.assetsSearchIndex == '') {
                this.$message.error('请选择标签分组');
            } else {
                var assetsSearchIndex = 0;
                if (this.assetsSearchIndex) {
                    assetsSearchIndex = this.assetsSearchIndex[this.assetsSearchIndex.length - 1];
                }
                var query = {
                    data: {
                        groupId: assetsSearchIndex
                    },
                    url: '/get_bindLabelList.cls.php'
                };
                fetchData(query).then((res) => {
                    if (res.code == 0) {
                        // this.$message.success(res.msg);
                    } else {
                        this.$message.error(res.msg);
                    }
                    that.assetsDate = res.data;
                    // 默认全选
                    this.$nextTick(() => {
                        for (let i = 0; i < this.assetsDate.length; i++) {
                            this.$refs.multipleTable1.toggleRowSelection(this.assetsDate[i], true);
                        }
                    });
                });
            }
        },
        // 监听学校选择器
        changeSchool() {
            this.getgoodList();
        },
        assetsSelectionChange(val) {
            this.assetsbatchList = [];
            for (var i = 0; i < val.length; i++) {
                this.assetsbatchList.push(val[i].id);
            }
        },
        // 批量操作
        handleSelectionChange(val) {
            this.batchList = [];
            for (var i = 0; i < val.length; i++) {
                this.batchList.push(val[i].id);
            }
        },
        // 用户搜索
        handleSearch() {
            this.getgoodList();
        },
        handleSearchAssets() {
            this.getassetsList();
        }
    }
};
</script>

<style scoped>
.el-cascader {
    width: 90%;
    padding-right: 10px;
}
.demonstration {
    color: #948c8c;
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.block2 {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
.upload-demo {
    width: 50rpx;
    position: relative;
}
</style>
