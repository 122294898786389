import request from '../utils/request';

export const fetchData = (query) => {

    var that = this

    // var URL = 'http://admin.dormi.hlsywy.com/class' + query.url + '?Token=' + window.sessionStorage.getItem('Token')
    var URL = '/class' + query.url + '?Token=' + window.sessionStorage.getItem('Token')
    request({
        url: '/class/get_loginInfo.cls.php?Token=' + window.sessionStorage.getItem('Token'),
        method: query.method || 'post',
        dataType: 'json',
        data: query.data,
    })
        .then((res) => {
            if (res.code == -1) {
                window.location.href = "http://admin.dormi.hlsywy.com/#/login";
            }
        }).catch((response) => {
            alert("网络错误");
            window.location.href = "http://admin.dormi.hlsywy.com/#/login";
        });

    return request({
        url: URL,
        method: query.method || 'post',
        dataType: 'json',
        data: query.data,
    })

};

// request({
//     url: 'http://admin.dormi.hlsywy.com/class/get_seleteInfoList.cls.php?Token=' + window.sessionStorage.getItem('Token') + '&t=' +
//         Date.parse(new Date()) / 1000,
//     method: 'post',
//     dataType: 'json',
// }).then((res) => {
//     // console.log(res)
//     window.sessionStorage.setItem('userType', JSON.stringify(res.data));
//     // console.log(res)
//     // console.log(window.sessionStorage.getItem('userType'))
// });