<template>
    <el-col :span="24" style="background-color: #ffffff">
        <el-col :span="24">
            <div class="container">
                <div class="handle-box">
                    <el-button type="primary" class="handle-del mr10 ml10" :disabled="updateButState" @click="updateBut"
                        >提交上传</el-button
                    >
                    <el-button type="warning" class="handle-del mr10 ml10" @click="excelDownload">模板下载</el-button>
                    <div style="float: left" class="handle-del mr10 ml10">
                        <el-upload
                            style="width: 50rpx"
                            action=""
                            :on-change="handleChange"
                            :show-file-list="false"
                            :on-remove="handleRemove"
                            :file-list="fileListUpload"
                            :limit="limitUpload"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            :auto-upload="false"
                        >
                            <el-button type="success" icon="el-icon-folder-add">批量添加{{ updateDate.length }}</el-button>
                        </el-upload>
                    </div>
                </div>
                <div class="handle-box">
                    <el-select v-model="schoolIndex" placeholder="学校" @change="getGroupList" style="float: left; margin-right: 10px">
                        <el-option v-for="item in schoolIndexList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                    <div class="handle-input mr10">
                        <el-cascader v-model="groupSearchIndex" style="width: 100%" :options="groupSearchList" clearable></el-cascader>
                    </div>
                    <div class="handle-input mr10">
                        <el-cascader
                            v-model="labelSelectIndex"
                            style="width: 100%"
                            :options="labelList"
                            :props="{ multiple: true }"
                            collapse-tags
                            clearable
                        ></el-cascader>
                    </div>
                </div>
                <el-table :data="tableData" border class="table" header-cell-class-name="table-header">
                    <el-table-column type="index" width="100" label="Excel 行号" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.$index + 3 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="房间名" label="房间名" align="center"></el-table-column>
                    <el-table-column prop="统一编号" label="统一编号" align="center"></el-table-column>
                    <el-table-column prop="房间床位数" label="房间床位数" align="center"></el-table-column>
                    <el-table-column prop="房间备注" label="房间备注" align="center"></el-table-column>
                </el-table>
            </div>
        </el-col>
        <!-- excell问题数据提示弹出框 -->
        <el-dialog title="Excel批量处理" :visible.sync="errorstate" width="30%">
            <el-table :data="errorList" border style="width: 100%">
                <el-table-column prop="reason" header-align="center" align="center" label="原因"> </el-table-column>
                <el-table-column prop="conent" header-align="center" align="center" label="详情"> </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="errorstate = false">取 消</el-button>
                <el-button type="primary" @click="errorstate = false">确定</el-button>
            </span>
        </el-dialog>
    </el-col>
</template>

<script>
import { fetchData } from '../../api/index';
export default {
    name: 'basetable',
    data() {
        return {
            query: {},
            tableData: [],
            list: {},
            fileListUpload: [],
            limitUpload: 3,
            errorList: [],
            errorstate: false,
            updateButState: true,
            addUser: {},
            schoolIndex: '',
            schoolIndexList: [],
            groupSearchList: [],
            groupSearchIndex: '',
            labelList: [],
            labelSelectIndex: '',
            updateDate: []
        };
    },
    created() {
        var that = this;
        var query = {
            url: '/get_regionList.cls.php'
        };
        fetchData(query).then((res) => {
            this.schoolIndexList = res.data;
            this.schoolIndex = res.data[0].id;
            that.getGroupList();
        });
        var query = {
            url: '/get_labelCascader.cls.php'
        };
        fetchData(query).then((res) => {
            that.labelList = res.data;
        });
    },
    activated() {},
    methods: {
        getGroupList() {
            var that = this;
            var query = {
                url: '/get_roomGroupCascader.cls.php',
                data: {
                    region: that.schoolIndex
                }
            };
            fetchData(query).then((res) => {
                that.groupSearchList = res.data;
            });
        },
        // 数据上传
        updateBut() {
            var that = this;
            if (this.groupSearchIndex == undefined || this.groupSearchIndex == '') {
                this.$message.error('请选择宿舍分组');
            } else if (this.labelSelectIndex == undefined || this.labelSelectIndex == '') {
                this.$message.error('请选择标签');
            } else if (that.updateDate.length > 0) {
                that.addUser = that.updateDate[0];
                var groupSearchIndex = 0;
                if (this.groupSearchIndex) {
                    groupSearchIndex = this.groupSearchIndex[this.groupSearchIndex.length - 1];
                }
                var label = [];
                for (var x = 0; x < that.labelSelectIndex.length; x++) {
                    label.push(this.labelSelectIndex[x][this.labelSelectIndex[x].length - 1]);
                }
                var query = {
                    data: {
                        region: that.schoolIndex,
                        name: that.addUser.房间名,
                        groupId: groupSearchIndex,
                        numb: that.addUser.统一编号,
                        bedNum: that.addUser.房间床位数,
                        remark: that.addUser.房间备注,
                        label: JSON.stringify(label)
                    },
                    url: '/create_room.cls.php'
                };
                fetchData(query).then((res) => {
                    if (res.code == 0 || res.code == 1) {
                        that.updateDate = that.updateDate.splice(1, that.updateDate.length);
                        var collegeObj = {};
                        var errorIndex = Number(that.errorList.length) + 3;
                        collegeObj.reason = 'Excell第' + errorIndex + '行';
                        collegeObj.conent = res.msg;
                        that.errorList.push(collegeObj);
                        setTimeout(this.updateBut, 100);
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                if (that.errorList.length > 0) {
                    this.updateButState = true;
                    this.errorstate = true;
                } else {
                    var collegeObj = {};
                    collegeObj.reason = '结果';
                    collegeObj.conent = '已全部成功上传';
                    that.errorList.push(collegeObj);
                    this.updateButState = true;
                    this.errorstate = true;
                }
            }
        },
        // excell模板下载
        excelDownload() {
            var that = this;
            var query = {
                url: '/get_excelDownUrl.cls.php',
                data: {
                    name: 'dormiAdminRoom'
                }
            };
            fetchData(query).then((res) => {
                if (res.code == 0) {
                    window.location.href = res.data.url;
                }
            });
        },

        // excell数据逐一上传核验
        auditData() {
            var that = this;
            this.errorList = [];
            var idErrorList = [];
            var nameErrorList = [];
            var numbErrorList = [];
            var collegeErrorList = [];
            var remarkErrorList = [];
            var bedNumErrorList = [];
            this.tableData = this.tableData.splice(1, this.tableData.length);
            this.updateDate = this.updateDate.splice(1, this.updateDate.length);
            for (var i = 0; i < this.tableData.length; i++) {
                var name = this.tableData[i].房间名;
                var numb = this.tableData[i].统一编号;
                var remark = this.tableData[i].房间备注;
                var bedNum = this.tableData[i].房间床位数;
                var errorIndex = i + 3;
                if (name == undefined || name == '') {
                    var nameObj = {};
                    nameObj.reason = '第' + errorIndex + '行  房间名为空';
                    nameObj.conent = '';
                    nameErrorList.push(nameObj);
                }
                if (numb == undefined || numb == '') {
                    var numbObj = {};
                    numbObj.reason = '第' + errorIndex + '行  统一编号为空';
                    numbObj.conent = '';
                    numbErrorList.push(numbObj);
                }
                if (!this.isNumber(bedNum)) {
                    var bedNumObj = {};
                    bedNumObj.reason = '第' + errorIndex + '行  房间床位数有误';
                    bedNumObj.conent = '';
                    bedNumErrorList.push(bedNumObj);
                }
                if (bedNum < 0 || bedNum > 9) {
                    var bedNumObj = {};
                    bedNumObj.reason = '第' + errorIndex + '行  房间床位数需大于0小于9';
                    bedNumObj.conent = '';
                    bedNumErrorList.push(bedNumObj);
                }
            }
            if (idErrorList.length > 0) {
                for (var i = 0; i < idErrorList.length; i++) {
                    this.errorList.push(idErrorList[i]);
                }
            }
            if (nameErrorList.length > 0) {
                for (var i = 0; i < nameErrorList.length; i++) {
                    this.errorList.push(nameErrorList[i]);
                }
            }
            if (numbErrorList.length > 0) {
                for (var i = 0; i < numbErrorList.length; i++) {
                    this.errorList.push(numbErrorList[i]);
                }
            }
            if (collegeErrorList.length > 0) {
                for (var i = 0; i < collegeErrorList.length; i++) {
                    this.errorList.push(collegeErrorList[i]);
                }
            }
            if (remarkErrorList.length > 0) {
                for (var i = 0; i < remarkErrorList.length; i++) {
                    this.errorList.push(remarkErrorList[i]);
                }
            }
            if (bedNumErrorList.length > 0) {
                for (var i = 0; i < bedNumErrorList.length; i++) {
                    this.errorList.push(bedNumErrorList[i]);
                }
            }
            if (this.errorList.length == 0) {
                this.updateButState = false;
            } else {
                this.updateButState = true;
                this.errorstate = true;
            }
        },
        // 判断是否为纯数字
        isNumber(val) {
            var regPos = /^\d+(\.\d+)?$/; //非负浮点数
            var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
            if (regPos.test(val) || regNeg.test(val)) {
                return true;
            } else {
                return false;
            }
        },
        handleChange(file, fileList) {
            this.fileTemp = file.raw;
            let fileName = file.raw.name;
            let fileType = fileName.substring(fileName.lastIndexOf('.') + 1);
            // 判断上传文件格式
            if (this.fileTemp) {
                if (fileType == 'xlsx' || fileType == 'xls') {
                    this.importf(this.fileTemp);
                } else {
                    this.$message({
                        type: 'warning',
                        message: '附件格式错误，请删除后重新上传！'
                    });
                }
            } else {
                this.$message({
                    type: 'warning',
                    message: '请上传附件！'
                });
            }
        },
        // 移除excel表
        handleRemove(file, fileList) {
            this.fileTemp = null;
        },
        // 导入EXCELL
        importf(obj) {
            this.dialogVisible = true;
            let _this = this;
            let inputDOM = this.$refs.inputer; // 通过DOM取文件数据
            this.file = event.currentTarget.files[0];
            var rABS = false; //是否将文件读取为二进制字符串
            var f = this.file;
            var reader = new FileReader();
            //if (!FileReader.prototype.readAsBinaryString) {
            FileReader.prototype.readAsBinaryString = function (f) {
                var binary = '';
                var rABS = false; //是否将文件读取为二进制字符串
                var pt = this;
                var wb; //读取完成的数据
                var outdata;
                var reader = new FileReader();
                reader.onload = function (e) {
                    var bytes = new Uint8Array(reader.result);
                    var length = bytes.byteLength;
                    for (var i = 0; i < length; i++) {
                        binary += String.fromCharCode(bytes[i]);
                    }
                    var XLSX = require('xlsx');
                    if (rABS) {
                        wb = XLSX.read(btoa(fixdata(binary)), {
                            //手动转化
                            type: 'base64'
                        });
                    } else {
                        wb = XLSX.read(binary, {
                            type: 'binary'
                        });
                    }
                    // outdata就是你想要的东西 excel导入的数据
                    outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
                    console.log(outdata);
                    // excel 数据再处理
                    let arr = [];
                    outdata.map((v) => {
                        // let jsonString = JSON.stringify(v).replace(/\*/g, '').replace(/\s/ig,'');
                        let jsonString = JSON.stringify(v).replace(/\//g, '').replace(/\s/gi, '');
                        v = JSON.parse(jsonString);
                        //console.log(v);
                        let obj = {};
                        //xxx代表列名
                        obj = v;
                        // obj.id = v.id
                        arr.push(obj);
                    });
                    _this.tableData = [];
                    _this.updateDate = [];
                    _this.tableData = [...arr];
                    _this.updateDate = [...arr];
                    _this.auditData();
                };
                reader.readAsArrayBuffer(f);
            };
            if (rABS) {
                reader.readAsArrayBuffer(f);
            } else {
                reader.readAsBinaryString(f);
            }
        }
    }
};
</script>

<style scoped>
.el-cascader {
    width: 90%;
    padding-right: 10px;
}
.demonstration {
    color: #948c8c;
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.block2 {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
.upload-demo {
    width: 50rpx;
    position: relative;
}
</style>
