<template>
    <el-col :span="24" style="background-color: #ffffff">
        <el-col :span="24">
            <div class="container">
                <div class="handle-box">
                    <el-select v-model="schoolIndex" placeholder="标签组" style="float: left; margin-right: 10px" @change="changeSchool">
                        <el-option v-for="item in schoolIndexList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                    <el-select v-model="colorIndex" placeholder="颜色" style="float: left; margin-right: 10px" @change="changeSchool">
                        <el-option v-for="item in colorAllList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                    <el-input v-model="query.keyword" placeholder="模糊检索" class="handle-input mr10"></el-input>
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
                </div>
                <div class="handle-box">
                    <el-button style="margin-right: 10px" type="primary" icon="el-icon-plus" @click="addBut" class="handle-del mr10 ml10"
                        >添加标签</el-button
                    >
                    <el-button style="margin-right: 10px" type="danger" icon="el-icon-delete" @click="batchBut" class="handle-del mr10 ml10"
                        >批量删除</el-button
                    >
                </div>
                <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
                    <el-table-column prop="name" label="标签名" align="center"></el-table-column>
                    <el-table-column prop="icon" label="图标" align="center"></el-table-column>
                    <el-table-column label="颜色" align="center">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.color == 'success'">绿色</el-tag>
                            <el-tag type="info" v-if="scope.row.color == 'info'">灰色</el-tag>
                            <el-tag type="warning" v-if="scope.row.color == 'warning'">橙色</el-tag>
                            <el-tag type="danger" v-if="scope.row.color == 'danger'">红色</el-tag>
                            <el-tag type="primary" v-if="scope.row.color == ''">蓝色</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" style="display: flex; justify-content: center; align-items: center">
                        <template slot-scope="scope" style="display: flex" @click="handleSelect(scope.row)">
                            <el-button
                                type="text"
                                style="margin-right: 10px"
                                icon="el-icon-edit"
                                @click="reviseBut(scope.$index, scope.row)"
                                >修改</el-button
                            >
                            <el-button type="text" icon="el-icon-delete" class="red" @click="handledelete(scope.$index, scope.row)"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        background
                        layout="total, prev, pager, next,sizes, jumper"
                        :current-page="query.pageIndex"
                        @size-change="handleSizeChange"
                        :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 500, 1000, 2000, 3000, 4000, 5000]"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                    ></el-pagination>
                </div>
            </div>
        </el-col>
        <!-- 添加弹出框 -->
        <el-dialog title="分组添加" :visible.sync="addstate" width="30%">
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="标签组" :required="true">
                    <el-select v-model="schoolIndex" placeholder="标签组" @change="changeSchool">
                        <el-option v-for="item in schoolIndexList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="标签名" :required="true">
                    <el-input v-model="add.groupName" placeholder="请输入标签名"></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="图标" :required="true">
                    <el-input v-model="add.icon" placeholder="图标" maxlength="1"></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="颜色" :required="true">
                    <el-select v-model="colorAdd" placeholder="颜色">
                        <el-option v-for="item in colorList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addstate = false">取 消</el-button>
                <el-button type="primary" @click="addOk">确定</el-button>
            </span>
        </el-dialog>
        <!-- 修改弹出框 -->
        <el-dialog title="修改" :visible.sync="revisestate" width="30%">
            <el-form ref="form" :model="add" label-width="150px">
                <el-form-item label="标签组" :required="true">
                    <el-select v-model="schoolIndex" placeholder="标签组" @change="changeSchool">
                        <el-option v-for="item in schoolIndexList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="revise" label-width="150px">
                <el-form-item label="标签名" :required="true">
                    <el-input
                        v-model="revise.name"
                        @input="inputChange($event)"
                        placeholder="请输入标签名"
                        @keyup.enter.native="reviseOk"
                    ></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="revise" label-width="150px">
                <el-form-item label="图标" :required="true">
                    <el-input v-model="revise.icon" placeholder="图标" maxlength="1" @input="inputChange($event)"></el-input>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="revise" label-width="150px">
                <el-form-item label="颜色" :required="true">
                    <el-select v-model="colorRevise" placeholder="颜色" @change="changeSchool">
                        <el-option v-for="item in colorList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="revisestate = false">取 消</el-button>
                <el-button type="primary" @click="reviseOk">确定</el-button>
            </span>
        </el-dialog>
    </el-col>
</template>

<script>
import { fetchData } from '../../api/index';
export default {
    data() {
        return {
            query: {
                pageIndex: 1,
                pageSize: 10
            },
            tableData: [],
            pageTotal: 0,
            addstate: false,
            add: {},
            schoolIndex: '',
            schoolIndexList: [],
            batchList: [],
            revise: {},
            revisestate: false,
            colorAllList: [
                {
                    name: '全部',
                    id: 'all'
                },
                {
                    name: '蓝色',
                    id: 'primary'
                },
                {
                    name: '绿色',
                    id: 'success'
                },
                {
                    name: '灰色',
                    id: 'info'
                },
                {
                    name: '橙色',
                    id: 'warning'
                },
                {
                    name: '红色',
                    id: 'danger'
                }
            ],
            colorIndex: 'all',
            colorList: [
                {
                    name: '蓝色',
                    id: 'primary'
                },
                {
                    name: '绿色',
                    id: 'success'
                },
                {
                    name: '灰色',
                    id: 'info'
                },
                {
                    name: '橙色',
                    id: 'warning'
                },
                {
                    name: '红色',
                    id: 'danger'
                }
            ],
            colorAdd: '',
            colorRevise: ''
        };
    },
    activated() {},
    created() {
        var that = this;
        var query = {
            url: '/get_labelGroupList.cls.php',
            data: {
                page: 1,
                limit: 9999
            }
        };
        fetchData(query).then((res) => {
            console.log(res);
            this.schoolIndexList = res.data;
            this.schoolIndex = res.data[0].id;
            this.getgoodList();
        });
    },
    methods: {
        // 添加
        addBut() {
            this.addstate = true;
        },
        //添加确认
        addOk() {
            var that = this;
            var colorAdd = '';
            if (that.colorAdd != 'primary') {
                colorAdd = this.colorAdd;
            }
            if (that.add.groupName == undefined || that.add.groupName == '') {
                this.$message.error('标签命不能为空');
            } else {
                var query = {
                    data: {
                        groupId: that.schoolIndex,
                        name: that.add.groupName,
                        color: colorAdd,
                        icon: that.add.icon
                    },
                    url: '/create_label.cls.php'
                };
                fetchData(query).then((res) => {
                    if (res.code == 0) {
                        this.addstate = false;
                        this.$message.success(res.msg);
                        this.getgoodList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        // 修改
        reviseBut(index, row) {
            var that = this;
            this.revise.name = row.name;

            if (row.color == '') {
                this.colorRevise = 'primary';
            } else {
                this.colorRevise = row.color;
            }
            this.revise.icon = row.icon;
            this.reviseId = row.id;
            this.revisestate = true;
        },
        // 修改上传
        reviseOk() {
            var that = this;
            if (that.revise.name == undefined || that.revise.name == '') {
                this.$message.error('分组命不能为空');
            } else {
                var colorRevise = '';
                if (that.colorRevise != 'primary') {
                    colorRevise = this.colorRevise;
                }
                var query = {
                    data: {
                        id: that.reviseId,
                        type: 'sole',
                        groupId: that.schoolIndex,
                        name: that.revise.name,
                        color: colorRevise,
                        icon: that.revise.icon
                    },
                    url: '/update_label.cls.php'
                };
                fetchData(query).then((res) => {
                    if (res.code == 0) {
                        this.revisestate = false;
                        this.$message.success(res.msg);
                        this.getgoodList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        // 监听学校选择器
        changeSchool() {
            this.getgoodList();
        },
        // 删除操作
        handledelete(index, row) {
            console.log(row);
            var that = this;
            // 二次确认删除
            this.$confirm('确定要删除该分组吗？', '提示', {
                type: 'warning'
            })
                .then(() => {
                    var query = {
                        data: {
                            delete: 1,
                            type: 'sole',
                            id: row.id
                        },
                        url: '/update_label.cls.php'
                    };
                    fetchData(query).then((res) => {
                        if (res.code == 0) {
                            that.$message.success(res.msg);
                            that.getgoodList();
                        } else {
                            that.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        //批量删除
        batchBut() {
            var that = this;
            if (this.batchList == undefined || that.batchList == '') {
                this.$message.error('请先勾选');
            } else {
                // 二次确认删除
                this.$confirm('确定要删除该分组吗？', '提示', {
                    type: 'warning'
                })
                    .then(() => {
                        var query = {
                            data: {
                                delete: 1,
                                type: 'batch',
                                id: JSON.stringify(this.batchList)
                            },
                            url: '/update_label.cls.php'
                        };
                        fetchData(query).then((res) => {
                            if (res.code == 0) {
                                that.$message.success(res.msg);
                                that.getgoodList();
                            } else {
                                that.$message.error(res.msg);
                            }
                        });
                    })
                    .catch(() => {});
            }
        },
        // 获取列表数据
        getgoodList() {
            var that = this;
            console.log(this.colorIndex);
            var colorIndex = '';
            if (that.colorIndex != 'primary') {
                colorIndex = this.colorIndex;
            }
            var query = {
                data: {
                    page: that.query.pageIndex,
                    limit: that.query.pageSize,
                    groupId: that.schoolIndex,
                    keyword: that.query.keyword,
                    color: colorIndex
                },
                url: '/get_labelList.cls.php'
            };
            fetchData(query).then((res) => {
                if (res.code == 0) {
                    // this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
                that.tableData = res.data;
                that.pageTotal = Number(res.count) || 0;
            });
        },
        // 用户搜索
        handleSearch() {
            (this.query.pageIndex = 1), this.getgoodList();
        },
        // 批量操作
        handleSelectionChange(val) {
            this.batchList = [];
            for (var i = 0; i < val.length; i++) {
                this.batchList.push(val[i].id);
            }
            console.log(this.batchList);
        },
        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val;
            this.$set(this.query, 'pageIndex', val);
            this.getgoodList();
        },
        handleSizeChange(val) {
            this.query.pageSize = val;
            this.getgoodList();
        },
        inputChange() {
            this.$forceUpdate();
        }
    }
};
</script>

<style scoped>
.el-cascader {
    width: 90%;
    padding-right: 10px;
}
.demonstration {
    color: #948c8c;
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 200px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.block2 {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
</style>
